@import 'src/scss/base';

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.test-results {
  padding-top: 82px;
  width: 100%;
  height: 100vh;
  background: $color-grey-light;

  @include lg {
    padding-top: 98px;
  }
}

.test-results__loader {
  width: 100%;
  height: 100vh;
  background: $color-grey-light;
  position: relative;
}

.test-results__loader::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: $color-secondary;
  border-radius: 50%;
  content: '';
  height: 40px;
  width: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}

.test-results__header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1001;
  background: $color-grey-light;
}

.test-results__logo {
  display: none;
  @include md {
    display: block;
  }
}
